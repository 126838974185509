import Plus from "images/homepage/Plus";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface IProps {
    question: ReactNode;
    answer: ReactNode;
    expanded: boolean;
    onClick?: () => void;
}

const Card = styled.div<{ expanded: boolean }>`
    color: var(--bs-code-color);
    background: none;
    display: block;
    text-align: left;
    font-size: 18px;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid #e8e8e8;

    cursor: ${(props) => (props.onClick ? "pointer" : "default")};

    @media (min-width: 992px) {
        max-width: 1084px;
        font-size: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

const Question = styled.p.attrs({
    className: "d-flex align-items-center",
})<{ expanded: boolean }>`
    margin-top: 0;
    opacity: ${(props) => (props.expanded ? "1" : "0.6")};
    margin-bottom: ${(props) => (props.expanded ? "2px" : "0")};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > strong {
        width: 70%;
    }

    & > svg {
        min-height: 24px;
        margin-left: 10px;
        transform: rotate(${(props) => (props.expanded ? "225deg" : "0deg")});
        transition: transform 0.25s ease-out;
    }
`;

const Answer = styled.div<{ height: number; expanded: boolean }>`
    overflow: hidden;
    height: ${(props) => (props.expanded ? props.height : 0)}px;

    transition: height 0.25s ease-out;
    width: 90%;
`;

export default function FAQCard({
    question,
    answer,
    expanded,
    onClick,
}: IProps) {
    const [height, setHeight] = useState(0);
    const ansRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ansRef.current) {
            setHeight(ansRef.current.scrollHeight || 0);
        }
    }, [ansRef.current]);

    return (
        <Card onClick={onClick} expanded={expanded}>
            <Question expanded={expanded}>
                <strong>{question}</strong>
                {onClick != null ? <Plus height="24" width="24" /> : <></>}
            </Question>
            <Answer height={height} expanded={expanded} ref={ansRef}>
                {answer}
            </Answer>
        </Card>
    );
}
